/* eslint-disable react/jsx-filename-extension */
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const SEO = (props) => {
  const { title, description, image, pathname, author, twitterUsername } = props;

  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultTitle,
            defaultDescription,
            siteUrl,
            defaultImage,
            defaultAuthor,
            defaultTwitterUsername,
          },
        },
      }) => {
        const seo = {
          title: title || defaultTitle,
          description: description || defaultDescription,
          image: `${siteUrl}${image || defaultImage}`,
          url: `${siteUrl}${pathname || ''}`,
          author: author || defaultAuthor,
          twitterUsername: twitterUsername || defaultTwitterUsername,
        };

        return (
          <Helmet
            title={seo.title}
            htmlAttributes={{
              lang: 'en-US',
            }}
            defer={false}
          >
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />

            <title>{seo.title}</title>

            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            <meta name="author" content={seo.author} />

            <meta property="og:title" content={seo.title} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={seo.image} />
            <meta property="og:url" content={seo.url} />
            <meta property="og:description" content={seo.description} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={seo.url} />
            <meta name="twitter:creator" content={seo.twitterUsername} />
            <meta name="twitter:description" content={seo.description} />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:image" content={seo.image} />
            <meta name="twitter:domain" content={seo.url} />
            <meta name="twitter:image:src" content={seo.image} />
          </Helmet>
        );
      }}
    />
  );
};

export default memo(SEO);

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        defaultAuthor: author
        defaultTwitterUsername: twitterUsername
      }
    }
  }
`;
